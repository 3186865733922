<template>
  <div class="mt-1 mx-3">
    <accounting-book></accounting-book>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AccountingBook from "@/components/accounting/accounting-book/index.vue";

export default {
  components: { AccountingBook },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Contabilità",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Foglio Quadratura",
      path: "module.CONT.FQUA",
      level: 2,
    });
  },
};
</script>
