<template>
  <b-modal
    id="viewConsolidationCalendar"
    size="sm"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="calendar"></b-icon>
        <span>Consolidamenti</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>

    <!-- <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn @click="cancel()" size="sm" variant="outline-secondary"
          >CHIUDI</b-btn
        >
      </b-form-group>
    </template> -->
    <consolidation-calendar
      v-model="consDate"
      @view="onView"
      :max="max"
      @reload="$emit('reload')"
    ></consolidation-calendar>
  </b-modal>
</template>
<script>
import ConsolidationCalendar from "@/components/ConsolidationCalendar";

export default {
  data() {
    return {
      consDate: null,
    };
  },
  components: {
    ConsolidationCalendar,
  },
  props: {
    max: null,
  },
  computed: {},
  // mounted() {
  //   this.$refs['myRelation'].firstChild.addEventListener('click', function(event) {
  //     event.preventDefault();
  //     console.log('clicked: ', event.target);
  //   })
  // },
  methods: {
    onView(date) {
      this.$emit("view", date);
    },
  },
};
</script>
<style lang="scss" scoped></style>
